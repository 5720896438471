//import calculateSize from "calculate-size";

export default (config) => {

  const {
    width,
    groupProp,
    subGroupProp,
    dataFormat,
    thresholdLineValue,
    thresholdLineLabel,
    emphasizeLines
  } = config;

  const labelProps = {
    "align": {"value": "center"},
    "fontSize": width > 600 ? {"value": 13} : {"value": 11},
    "font": {"value": "stevie-sans, sans-serif"},
    "fontWeight": {"value": 400},
    "fill": {"value": "#fff"}
  };

  const labelBoldProps = {
    "align": {"value": "center"},
    "fontSize": width > 600 ? {"value": 14} : {"value": 12},
    "font": {"value": "stevie-sans, sans-serif"},
    "fontWeight": {"value": 800},
    "fill": {"value": "#353531"}
  };

  const thresholdLabelSize = () => calculateSize(thresholdLineLabel, {
    font: "stevie-sans, sans-serif",
    fontSize: "12.4px",
    fontWeight: 500
  });

  const emphasizedLines = (emphasizeLines || "").split(",").map(s => s.trim());

  const barTooSmallTest = "abs(scale('yscale', 0) - scale('yscale', datum.Value)) < 27";
  const barPositiveTest = "datum.Value >= 0";
  const barNegativeTest = "datum.Value < 0";

  const barLabelProps = ({
    tooSmallAndNegative,
    tooSmallAndPositive,
    negative,
    positive
  }) => [
    {
      "test": `${barTooSmallTest} && ${barPositiveTest}`,
      "value": tooSmallAndPositive
    },
    {
      "test": `${barTooSmallTest} && ${barNegativeTest}`,
      "value": tooSmallAndNegative
    },
    {
      "test": barNegativeTest,
      "value": negative
    },
    {"value": positive}
  ];

  return {
    groupedBars: {
      "type": "group",
      "name": "bars",
      "from": {
        "facet": {
          "data": "table",
          "name": "facet",
          "groupby": groupProp
        }
      },
      "encode": {
        "enter": {
          "x": {"scale": "xscale", "field": groupProp}
        }
      },

      "signals": [
        {"name": "width", "update": "bandwidth('xscale')"}
      ],

      "scales": [
        {
          "name": "pos",
          "type": "band",
          "range": "width",
          "domain": {"data": "facet", "field": subGroupProp}
        }
      ],

      "marks": [
        ...[{
          "name": "bars",
          "from": {"data": "facet"},
          "type": "rect",
          "encode": {
            "enter": {
              "x": {"scale": "pos", "field": subGroupProp},
              "width": {"scale": "pos", "band": 1},
              "y": {"scale": "yscale", "field": "Value"},
              "y2": {"scale": "yscale", "value": 0},
              "fill": {"scale": "color", "field": subGroupProp}
            }
          }
        }],
        ...(width > 500 ? [{
          "name": "labels",
          "from": {"data": "facet"},
          "type": "text",
          "encode": {
            "enter": Object.assign({}, labelProps, {
              "x": {"scale": "pos", "field": subGroupProp},
              "dx": {"scale": "pos", "band": 0.5},
              "y": {"scale": "yscale", "field": "Value"},
              "dy": barLabelProps({
                tooSmallAndNegative: 10,
                tooSmallAndPositive: -7,
                negative: -10,
                positive: 10
              }),
              "baseline": barLabelProps({
                tooSmallAndNegative: "top",
                tooSmallAndPositive: "bottom",
                negative: "bottom",
                positive: "top"
              }),
              "fill": barLabelProps({
                tooSmallAndNegative: "#6c6c69",
                tooSmallAndPositive: "#6c6c69",
                negative: "white",
                positive: "white"
              }),
              "text": {"signal": `format(datum.Value, '${dataFormat}')`}
            })
          }
        },
        {
          "name": "sublabels",
          "from": {"data": "facet"},
          "type": "text",
          "encode": {
            "enter": Object.assign({}, labelProps, {
              "x": {"scale": "pos", "field": subGroupProp},
              "dx": {"scale": "pos", "band": 0.5},
              "y": {"scale": "yscale", "field": "Value"},
              "dy": barLabelProps({
                tooSmallAndNegative: 27,
                tooSmallAndPositive: -24,
                negative: -27,
                positive: 27
              }),
              "baseline": barLabelProps({
                tooSmallAndNegative: "top",
                tooSmallAndPositive: "bottom",
                negative: "bottom",
                positive: "top"
              }),
              "fill": barLabelProps({
                tooSmallAndNegative: "#6c6c69",
                tooSmallAndPositive: "#6c6c69",
                negative: "white",
                positive: "white"
              }),
              "text": {"field": "Sublabel"},
              "opacity": [
                {
                  "test": "!datum.Sublabel",
                  "value": 0
                },
                {"value": 1}
              ]
            })
          }
        }] : [])
      ]
    },
    simpleBars: {
      "type": "rect",
      "name": "bars",
      "from": {"data": "table"},
      "encode": {
        "enter": {
          "x": {"scale": "xscale", "field": groupProp},
          "width": {"scale": "xscale", "band": 1, "offset": -1},
          "y": {"scale": "yscale", "field": "Value"},
          "y2": {"scale": "yscale", "value": 0},
          "fill": {"scale": "color", "field": subGroupProp}
        }
      }
    },
    simpleBarLabels: {
      "name": "labels",
      "from": {"data": "table"},
      "type": "text",
      "encode": {
        "enter": Object.assign({}, labelProps, {
          "x": {"scale": "xscale", "field": groupProp},
          "dx": {"scale": "xscale", "band": 0.5},
          "y": {"scale": "yscale", "field": "Value"},
          "dy": barLabelProps({
            tooSmallAndNegative: 10,
            tooSmallAndPositive: -7,
            negative: -10,
            positive: 10
          }),
          "baseline": barLabelProps({
            tooSmallAndNegative: "top",
            tooSmallAndPositive: "bottom",
            negative: "bottom",
            positive: "top"
          }),
          "fill": barLabelProps({
            tooSmallAndNegative: "#6c6c69",
            tooSmallAndPositive: "#6c6c69",
            negative: "white",
            positive: "white"
          }),
          "text": {"signal": `format(datum.Value, '${dataFormat}')`},
          "opacity": {"value": 1}
        })
      }
    },
    simpleBarSubLabels: {
      "name": "sublabels",
      "from": {"data": "table"},
      "type": "text",
      "encode": {
        "enter": Object.assign({}, labelProps, {
          "x": {"scale": "xscale", "field": groupProp},
          "dx": {"scale": "xscale", "band": 0.5},
          "y": {"scale": "yscale", "field": "Value"},
          "dy": barLabelProps({
            tooSmallAndNegative: 27,
            tooSmallAndPositive: -24,
            negative: -27,
            positive: 27
          }),
          "baseline": barLabelProps({
            tooSmallAndNegative: "top",
            tooSmallAndPositive: "bottom",
            negative: "bottom",
            positive: "top"
          }),
          "fill": barLabelProps({
            tooSmallAndNegative: "#6c6c69",
            tooSmallAndPositive: "#6c6c69",
            negative: "white",
            positive: "white"
          }),
          "text": {"field": "Sublabel"},
          "opacity": [
            {
              "test": "!datum.Sublabel",
              "value": 0
            },
            {"value": 1}
          ]
        })
      }
    },
    stackedBars: {
      "type": "rect",
      "name": "bars",
      "from": {"data": "table-stacked"},
      "encode": {
        "enter": {
          "x": {"scale": "xscale", "field": groupProp},
          "width": {"scale": "xscale", "band": 1, "offset": -1},
          "y": {"scale": "yscale", "field": "y0"},
          "y2": {"scale": "yscale", "field": "y1"},
          "fill": {"scale": "color", "field": subGroupProp}
        }
      }
    },
    stackedBarLabels: {
      "name": "labels",
      "from": {"data": "table-stacked"},
      "type": "text",
      "encode": {
        "enter": Object.assign({}, labelProps, {
          "x": {"scale": "xscale", "field": groupProp},
          "dx": {"scale": "xscale", "band": 0.5},
          "y": {"scale": "yscale", "field": "y1"},
          "dy": {"value": 10},
          "baseline": {"value": "top"},
          "text": {"signal": `format(datum.Value, '${dataFormat}')`},
          "opacity": [
            {
              "test": "abs(scale('yscale', datum.y1) - scale('yscale', datum.y0)) < 49",
              "value": 0
            },
            {"value": 1}
          ]
        })
      }
    },
    stackedBarSubLabels: {
      "name": "sublabels",
      "from": {"data": "table-stacked"},
      "type": "text",
      "encode": {
        "enter": Object.assign({}, labelProps, {
          "x": {"scale": "xscale", "field": groupProp},
          "dx": {"scale": "xscale", "band": 0.5},
          "y": {"scale": "yscale", "field": "y1"},
          "dy": {"value": 27},
          "baseline": {"value": "top"},
          "text": {"field": "Sublabel"},
          "opacity": [
            {
              "test": "abs(scale('yscale', datum.y1) - scale('yscale', datum.y0)) < 49",
              "value": 0
            },
            {
              "test": "!datum.Sublabel",
              "value": 0
            },
            {"value": 1}
          ]
        })
      }
    },
    stackedOverallBarLabels: {
      "name": "labels-sum",
      "from": {"data": "table-stacked-sum"},
      "type": "text",
      "encode": {
        "enter": Object.assign({}, labelBoldProps, {
          "x": {"scale": "xscale", "field": groupProp},
          "dx": {"scale": "xscale", "band": 0.5},
          "y": {"scale": "yscale", "field": "sum"},
          "dy": {"value": -10},
          "baseline": {"value": "bottom"},
          "text": {"signal": `format(datum.sum, '${dataFormat}')`},
          "opacity": [
            {
              "test": "height - scale('yscale', datum.sum) < 27",
              "value": 0
            },
            {"value": 1}
          ]
        })
      }
    },
    interactiveAreas: {
      "type": "rect",
      "name": "interactive-areas",
      "from": {"data": "table"},
      "encode": {
        "enter": {
          "x": {"scale": "fullwidth-xscale", "field": groupProp},
          "width": {"scale": "fullwidth-xscale", "band": 1},
          "y": {"value": 0},
          "y2": {"signal": "height"},
          "opacity": {"value": 0}
        }
      }
    },
    backgroundAreas: {
      "type": "rect",
      "name": "background-areas",
      "from": {"data": "table"},
      "encode": {
        "enter": {
          "x": {"scale": "fullwidth-xscale", "field": groupProp},
          "width": {"scale": "fullwidth-xscale", "band": 1},
          "y": {"value": 0},
          "y2": {"signal": "height"},
          "fill": {"value": "#d1d1d0"}
        },
        "update": {
          "opacity": [
            {
              "test": `activeGroup === (datum['${groupProp}'])`,
              "value": 0.2
            },
            {"value": 0}
          ]
        }
      }
    },
    threshold: {
      "type": "group",
      "name": "threshold",
      "encode": {
        "enter": {
          "y": {"scale": "yscale", "value": thresholdLineValue}
        }
      },
      "marks": [
        ...[{
          "type": "rule",
          "name": "threshold-line-background",
          "encode": {
            "enter": {
              "x": {"value": 10},
              "x2": {"signal": "width"},
              "y": {"value": 0},
              "y2": {"value": 0},
              "stroke": {"value": "#fff"},
              "strokeWidth": {"value": 6},
              "strokeDash": {"value": [1,7]},
              "strokeCap": {"value": "round"}
            }
          }
        },
        {
          "type": "rule",
          "name": "threshold-line",
          "encode": {
            "enter": {
              "x": {"value": 10},
              "x2": {"signal": "width"},
              "y": {"value": 0},
              "y2": {"value": 0},
              "stroke": {"value": "#a3a3a1"},
              "strokeWidth": {"value": 4},
              "strokeDash": {"value": [1,7]},
              "strokeCap": {"value": "round"}
            }
          }
        }],
        ...(width > 600 ? [{
          "type": "text",
          "name": "threshold-label",
          "encode": {
            "enter": {
              "x": {"signal": "width"},
              "y": {"value": -8},
              "align": {"value": "right"},
              "baseline": {"value": "middle"},
              "font": {"value": "stevie-sans, sans-serif"},
              "fontSize": {"value": 12},
              "fontWeight": {"value": 500},
              "fill": {"value": "#353531"},
              "text": {"value": thresholdLineLabel}
            }
          }
        }]: [])
      ]
    },
    trendLines: [{
      "type": "group",
      "from": {
        "facet": {
          "name": "series",
          "data": "table",
          "groupby": subGroupProp
        }
      },
      "signals": [
        {"name": "emphasizedLines", "value": emphasizedLines}
      ],
      "marks": [
        {
          "type": "line",
          "name": "lines",
          "from": {"data": "series"},
          "encode": {
            "enter": {
              "x": {"scale": "xscale", "field": groupProp},
              "y": {"scale": "yscale", "field": "Value"},
              "stroke": {"scale": "color", "field": subGroupProp},
              "defined": {"signal": "datum.Value !== null"},
              "strokeWidth": [
                {
                  "test": "indexof(emphasizedLines, datum.Series) >= 0",
                  "value": 6
                },
                {"value": 3},
              ],
              "strokeCap": {"value": "round"}
            }
          }
        }
      ]
    },
    {
      "type": "group",
      "from": {
        "facet": {
          "name": "series",
          "data": "definedtable",
          "groupby": subGroupProp
        }
      },
      "marks": [
        ...(width > 500 ? [
        {
          "type": "symbol",
          "name": "dots",
          "shape": "circle",
          "from": {"data": "series"},
          "encode": {
            "enter": {
              "x": {"scale": "xscale", "field": groupProp},
              "y": {"scale": "yscale", "field": "Value"},
              "size": {"value": 200},
              "fill": {"scale": "color", "field": subGroupProp},
              "stroke": {"value": "#fff"},
              "strokeWidth": {"value": 3}
            },
            "update": {
              "opacity": [
                {
                  "test": "activeGroup === datum.Timeframe",
                  "value": 1
                },
                {
                  "test": "datum.Label",
                  "value": 1
                },
                {"value": 0}
              ]
            }
          }
        },
        {
          "type": "text",
          "name": "dot-labels",
          "from": {"data": "series"},
          "encode": {
            "enter": Object.assign({}, labelBoldProps, {
              "x": {"scale": "xscale", "field": groupProp},
              "y": {"scale": "yscale", "field": "Value"},
              "text": {"signal": `format(datum.Value, '${dataFormat}')`},
              "align": {"value": "center"},
              "opacity": [
                {
                  "test": "datum.Label",
                  "value": 1
                },
                {"value": 0}
              ],
              "dy": [
                {
                  "test": "datum.Label === 'Above'",
                  "value": -10
                },
                {
                  "value": 10
                }
              ],
              "baseline": [
                {
                  "test": "datum.Label === 'Above'",
                  "value": "bottom"
                },
                {
                  "value": "top"
                }
              ]
            })
          }
        },
        {
          "type": "text",
          "name": "dot-sublabels",
          "from": {"data": "series"},
          "encode": {
            "enter": Object.assign({}, labelBoldProps, {
              "x": {"scale": "xscale", "field": groupProp},
              "y": {"scale": "yscale", "field": "Value"},
              "text": {"field": "Note"},
              "align": {"value": "center"},
							"fontWeight": {"value": 500},
							"fontSize": {"value": 13},
              "opacity": [
                {
                  "test": "datum.Note",
                  "value": 1
                },
                {"value": 0}
              ],
              "dy": [
                {
                  "test": "datum.Label === 'Above'",
                  "value": -27
                },
                {
                  "value": 27
                }
              ],
              "baseline": [
                {
                  "test": "datum.Label == 'Above'",
                  "value": "bottom"
                },
                {
                  "value": "top"
                }
              ]
            })
          }
        }]: [])]
    }],
    trendLabels: {
      "type": "group",
      "name": "trend-labels",
      "from": {
        "facet": {
          "name": "series",
          "data": "latest",
          "groupby": subGroupProp
        }
      },
      "marks": [
        {
          "type": "text",
          "from": {"data": "series"},
          "encode": {
            "enter": Object.assign({}, labelProps, {
              "x": {"scale": "xscale", "field": groupProp},
              "dx": {"value": 10},
              "y": {"scale": "yscale", "field": "Value"},
              "text": {"field": subGroupProp},
              "align": {"value": "left"},
              "baseline": {"value": "middle"},
              "fill": {"value": "#353531"},
              "limit": {"value": 150},
            })
          }
        }
      ]
    }
  };
};
