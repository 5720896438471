import React from "react";
import classnames from "classnames";
import { arrayOf, string, object, func, bool, number, shape, oneOfType } from "prop-types";
import { isLastPage } from "../paging";
import { openFilters } from "../filterSlider";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "./LoadMore.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import SortBar from "./SortBar.jsx";
import SearchStatus from "./SearchStatus.jsx";
import Pagination from "./Pagination.jsx";
import {RESULTS_DISPLAY_REFRESH, RESULTS_DISPLAY_APPEND} from "../redux/resultsDisplay";

export default class ResultList extends React.Component {
  static propTypes = {
    results: arrayOf(shape({
      id: oneOfType([number, string])
    })),
    resultsDisplayStrategy: number,
    selectedFacets: arrayOf(object).isRequired,
    totalResults: number.isRequired,
    sorters: arrayOf(shape({
      id: oneOfType([number, string]).isRequired,
      label: string.isRequired,
      value: string.isRequired,
      direction: oneOfType([bool, string]),
      isActive: bool.isRequired
    })),
    loading: bool,
    isFirstLoad: bool,
    nextPage: func,
    gotoPage: func,
    changeSort: func,
    showMobileFacets: func,
    dismissFacet: func,
    query: shape({
      q: string,
      sortBy: string,
      page: number,
      perPage: number,
      pageId: string
    }),
    pagination: bool,
    dictionary: object,
    display: object
  };
  
  static defaultProps = {
    selectedFacets: []
  };

  showFilters() {
    openFilters(); // DOM ops
    showMobileFacets(); // action creator
  }

  wrapperClass() {
    const isRefreshing = this.props.loading && this.props.resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending = this.props.loading && this.props.resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results", { "is-refreshing": isRefreshing, "is-appending": isAppending });
  }

  resultsRange() {
    // Upper bound of visible results
    let ceiling = (this.props.query.page * this.props.query.perPage );

    // Lower bound of visible results
    let floor = (ceiling - this.props.query.perPage) + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > this.props.totalResults) {
      ceiling = this.props.totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (this.props.totalResults <= this.props.query.perPage) {
      return `${this.props.totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    if (this.props.resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
    	// if we are using load more and is first load, we may be trying to 
			// display more 

      return `${ceiling}`;
    }

    // otherwise, return the full range
    return `${floor} - ${ceiling}`;
  }

  renderPagingControl() {
    const isLast = isLastPage(this.props.totalResults, this.props.query.perPage, this.props.query.page);

    if (this.props.pagination) {
      return (
        <Pagination
          totalResults={this.props.totalResults}
          resultsPerPage={this.props.query.perPage}
          currentPage={this.props.query.page}
          handlePageChange={this.props.gotoPage}
          dictionary={this.props.dictionary} />
      );
    }

    if (!this.props.pagination && !isLast) {
      return <LoadMore handleClick={this.props.nextPage} dictionary={this.props.dictionary} loading={this.props.loading} />;
    }
  }

  renderFilterToggle() {
    return (
      <button type="button"
              className="search-results__filter-open btn btn--primary"
              onClick={ () => showFilters() }>
        {this.props.dictionary.filterYourResultsText}
        <svg className="icon">
          <use xlinkHref="/assets/build/img/svg-sprite.svg#equalizer"></use>
        </svg>
      </button>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isFirstLoad && prevProps.results !== this.props.results) {
      addthis.layers.refresh();
    }
  }

  render() {
    const {
      results,
      resultsDisplayStrategy,
      selectedFacets,
      totalResults,
      loading,
      isFirstLoad,
      sorters,
      nextPage,
      gotoPage,
      dismissFacet,
      changeSort,
      query,
      pagination,
      dictionary,
      display,
      showMobileFacets
    } = this.props;
    const shouldShowNoResults = this.props.results.length === 0 && !this.props.isFirstLoad;
    const shouldShowPager = this.props.totalResults > this.props.query.perPage;

    return (
      <section className={this.wrapperClass()}>
        { this.renderFilterToggle() }
        <div className="search-results__status-and-sort">
            <SearchStatus
              resultsRange={this.resultsRange()}
              totalResults={totalResults}
              keywords={query.q}
              selectedFacets={selectedFacets}
              handleFacetDismiss={dismissFacet}
              dictionary={dictionary}
            />
            <SortBar sorters={sorters} handleSortClick={changeSort} dictionary={dictionary} />
        </div>
        <div className="search-results__item-wrapper">
          { loading && (
            <div className="search-results__loader">
              <LoadingSpinner/>
            </div>
          )}
          <div className="search-results__items">
            {
              shouldShowNoResults ?  (
                  <p>{dictionary.noResultsText}</p>
                ) : null
            }
            { results.map( (item) => <ResultItem key={item.id} {...item} dictionary={dictionary} display={display} />) }
          </div>
        </div>
        { shouldShowPager ? this.renderPagingControl() : null }
      </section>
    );
  }
}