import $ from "jquery";
import * as R from 'ramda';
import {showPopup, hidePopup} from "./popupBuilder.js";
import {customFormat} from "../format/format.js";
import {scaleOrdinal} from "d3-scale";

const groupedDataTemplate = (options, data) => `
  <span class="vega-hover__title">${data.group}</span>
  <table>
    ${
      !R.isNil(options.thresholdLineValue) ? 
        `<tr>
           <td>
             <svg class="vega-hover__chip" width="14" height="12">
               <line x1="2" y1="6" x2="10" y2="6" stroke-width="4" 
                stroke-linecap="round" stroke="#a3a3a1" stroke-dasharray="1,6"></line>
             </svg>
           </td>
           <td>
             <span class="vega-hover__label">${options.thresholdLineLabel}</span>
           </td>
           <td class="right-align">
             <span class="vega-hover__value">${customFormat(options.thresholdLineValue, options.dataFormat)}</span>
           </td>
         </tr>` : ""
    }
    ${data.subGroups.map(d => `
        <td>
          <svg class="vega-hover__chip" width="14" height="12">
            ${ options.type === "trend" ?
              `<line 
                x1="${d.isEmphasized? 4 : 2}" 
                x2="${d.isEmphasized? 10 : 12}" 
                y1="6" 
                y2="6" 
                stroke-width="${d.isEmphasized? 7 : 4}" 
                stroke-linecap="round" 
                stroke="${d.color}"></line>` :
              `<rect x="0" y="0" width="12" height="12" fill="${d.color}"></rect>` 
            }
          </svg>
        </td>
        <td>
          <span class="vega-hover__label">${d.subGroup}</span>
        </td>
        <td class="right-align">
          <span class="vega-hover__value">${d.formattedValue}</span>
          ${ d.subLabel ?
            `<span class="vega-hover__subvalue">${d.subLabel}</span>` : ""
          }
        </td>
      </tr>
    `).join("")}
  </table>
 `;

const simpleDataTemplate = (config, data) => `
  <span class="vega-hover__title">${data.group}</span>
  <span class="vega-hover__value">${data.formattedValue}</span>
  ${ data.subLabel ?
    `<span class="vega-hover__subvalue">${data.subLabel}</span>` : ""
  }
 `;

function point(node, event) {
  var svg = node.ownerSVGElement || node;

  if (svg.createSVGPoint) {
    var point = svg.createSVGPoint();
    point.x = event.clientX, point.y = event.clientY;
    point = point.matrixTransform(node.getScreenCTM().inverse());
    return [point.x, point.y];
  }

  var rect = node.getBoundingClientRect();
  return [event.clientX - rect.left - node.clientLeft, event.clientY - rect.top - node.clientTop];
}

function mousePositionRelativeToContainer(node, event) {
  if (event.changedTouches) event = event.changedTouches[0];
  return point(node, event);
}

const registerPopup = (spec, container, view, generateContent) =>  {

  let popupPosition; 

  // Hack, because Firefox doesn't support the "event" signal being passed in
  view.addEventListener('mousemove', function(event, item){
    popupPosition = mousePositionRelativeToContainer(container[0], event);
  });

  let touchTimer;
  view.addEventListener('touchstart', function(event, item){
    popupPosition = mousePositionRelativeToContainer(container[0], event);
    if(touchTimer){
      clearTimeout(touchTimer);
    }
    touchTimer = setTimeout(() => {
        hidePopup(container);
    }, 5000);
  });

  if (spec.signals && R.find(R.propEq('name', 'tooltip'), spec.signals)) {
    view.removeSignalListener('tooltip');
    view.addSignalListener('tooltip', (name, value) => setTimeout(() => {
      if (!value || !popupPosition) {
        hidePopup(container);
        return;
      }

      var [x,y] = popupPosition;

      var left = x;
      var top = y;

      const content = generateContent(value);

      showPopup(container, content, left, top, {
        top: 5, right: 5, bottom: 5, left: 5
      });
    }));
  }
};

export const registerBarPopup = (spec, options, container, view) =>  {

  registerPopup(spec, container, view, function(value){
		const colors = scaleOrdinal().domain(value.colors.domain).range(value.colors.range);

    const subGroupValues = value.data.filter(d => d.Group === value.group);

    return subGroupValues.length > 1 ?
      groupedDataTemplate(options, {
        group: value.group,
        subGroups: subGroupValues.map(d => ({
          color: colors(d.Subgroup),
          subGroup: d.Subgroup,
          subLabel: d.Sublabel,
          formattedValue: R.isNil(d.Value) ? 'N/A' : customFormat(d.Value, options.dataFormat)
        }))
      }) :
      simpleDataTemplate(options, {
        group: value.group,
        subLabel: subGroupValues[0].Sublabel,
        formattedValue: R.isNil(subGroupValues[0].Value) ? 'N/A' : customFormat(subGroupValues[0].Value, options.dataFormat)
      });
  });
};

export const registerTrendPopup = (spec, options, container, view) =>  {

  registerPopup(spec, container, view, function(value){
		const colors = scaleOrdinal().domain(value.colors.domain).range(value.colors.range);

    const subGroupValues = value.data.filter(d => d.Timeframe === value.group);

    const emphasizedLines = (options.emphasizeLines || "").split(",").map(s => s.trim());

    return subGroupValues.length > 1 ?
      groupedDataTemplate(options, {
        group: value.group,
        subGroups: subGroupValues.map(d => ({
          color: colors(d.Series),
          subGroup: d.Series,
          isEmphasized: R.contains(d.Series, emphasizedLines),
          formattedValue: R.isNil(d.Value) ? 'N/A' : customFormat(d.Value, options.dataFormat)
        }))
      }) :
      simpleDataTemplate(options, {
        group: value.group,
        formattedValue: R.isNil(subGroupValues[0].Value) ? 'N/A' : customFormat(subGroupValues[0].Value, options.dataFormat)
      });
  });
};
