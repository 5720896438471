import "./polyfill";
import $ from "jquery";
import "picturefill";
import "addtocalendar";
import {renderChart, preProcessResponsiveTable} from "./TBF/charts/index.js";
import {initSearchApp} from "./TBF/search/index.js";
import {initReportNav} from "./TBF/report-nav/index.js";
import {PrimaryNav} from "./TBF/navigation/PrimaryNav.js";
import {UtilityNav} from "./TBF/navigation/UtilityNav.js";
import {ViewAllExpand} from "./TBF/ViewAllExpand.js";
import {AgendaList} from "./TBF/AgendaList.js";
import {ImageSlider} from "./TBF/ImageSlider.js";
import {SecondaryNav} from "./TBF/navigation/SecondaryNav.js";
import SearchBox from "./TBF/navigation/SearchBox.js";
import Dropdown from "./TBF/navigation/Dropdown.js";
import {YtVideo} from "./TBF/video/YtVideo.js";
import {VimeoVideo} from "./TBF/video/VimeoVideo.js";
import {InlineVimeoVideo} from "./TBF/video/InlineVimeoVideo.js";
import {PdfModal} from "./TBF/PdfModal.js";
import {DynamicBanner} from "./TBF/DynamicBanner.js"
import Alert from "./TBF/Alert.js"
import {BackToTop} from "./TBF/navigation/BackToTop.js"
import {EmailDecrypt} from "./TBF/EmailDecrypt.js"
import {breakpoint} from "./TBF/breakpoint.js"
import {PageSlider} from "./TBF/PageSlider";

// Page Slider
const $pageSliderEl = $('.page-banner-slider')
if ($pageSliderEl.length) {
  new PageSlider($pageSliderEl);
}

// PrimaryNav
const $primaryNavEl = $(".js-primary-nav");
if ($primaryNavEl.length) {
  new PrimaryNav($primaryNavEl);
}

// Back To Top
const $backToTopEl = $(".back-to-top");
if ($backToTopEl.length) {
  new BackToTop($backToTopEl, $primaryNavEl);
}

// UtilityNav
const $utilityNavEl = $(".js-utility-nav");
if ($utilityNavEl.length) {
  new UtilityNav($utilityNavEl);
}

// Search Box
const $searchBox = $(".js-search-box");
if ($searchBox.length) {
  new SearchBox($searchBox);
}

// Dropdown
const dropdown = $(".js-dropdown");
if (dropdown.length) {
  new Dropdown(dropdown);
}

// YtVideo
const $videos = $.makeArray($(".js-video"));
if ($videos.length) {
  new YtVideo($videos);
}

// VimeoVideo
const $vimeoVideos = $.makeArray($(".js-vimeo-video"));
if ($vimeoVideos.length) {
  new VimeoVideo($vimeoVideos);
}

// InlineVimeoVideo
const $inlineVimeoVideos = $.makeArray($(".inline-vimeo-video"));
if ($inlineVimeoVideos.length) {
  new InlineVimeoVideo($inlineVimeoVideos);
}

// InlineVimeoVideo
const $pdfModals = $.makeArray($(".inline-pdf"));
if ($pdfModals.length) {
  new PdfModal($pdfModals);
}

const $alert = $(".js-alert");
if ($alert.length) {
  new Alert($alert);
}

// Expandable Listings
$.makeArray($(".js-view-all-expand")).map(el => {
  return new ViewAllExpand($(el));
});

// Agenda List
$.makeArray($(".js-agenda-list")).map(el => {
  return new AgendaList($(el));
});

// Charts
$(".vega-chart").each(function(){
	renderChart($(this));
});

$(".chart-block__table").each(function(){
	preProcessResponsiveTable($(this));
});

// Slideshows

// do not intialize slideshows inside
// page editor
if (!$("body").hasClass("is-page-editor")) {
  // slideshows
  $.makeArray($(".slideshow .js-slider")).map(el => {
    return new ImageSlider($(el), {});
  });
}

// Secondary Nav
if ($(".js-secondary-nav").length) {
  new SecondaryNav($(".js-secondary-nav"));
}

// Dynamic Banners (Hero and Page Banner)
const $banners = $(".js-dynamic-banner");
if ($banners.length) {
  const dynamicBanners = $.makeArray($banners).map(el => {
    return new DynamicBanner($(el));
  });
}

// Search Apps
// only init if results are included
if ($(".js-search-results").length) {
  initSearchApp($.makeArray($(".js-search-query,.js-search-results,.js-search-facets")));
}

if ($(".site-bostonindicators").length) {
  //only run on boston indicators
  var width;
  var newHeight;
  var searchPosition = function() {
    // width = $(window).width() / parseFloat($("body").css("font-size"));
      width = $(window).width();
    if (width <= 910) {
      var bannerHeight = $(".site-logo__image").height();
      console.log(bannerHeight);
      newHeight = (bannerHeight * 1.5);
      $(".condensed-header__top-left").find(".search-box").css("top", newHeight);
      // }
    } else {
      $(".condensed-header__top-left").find(".search-box").removeAttr("style");
    }
  }

	$(document).ready(searchPosition());
	$(window).on("resize",searchPosition);
}

// Report Nav
if ($("#js-report-nav").length) {
  initReportNav(document.getElementById("js-report-nav"));
}

// Email Decryptor
const $emailLinks = $.makeArray($(".js-email-link"));
const $emailTexts = $.makeArray($(".js-email-text"));
if ($emailLinks.length || $emailTexts.length) {
  new EmailDecrypt($emailLinks, $emailTexts);
}
