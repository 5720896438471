import $ from "jquery";
import "./custom-vendor/slick-carousel/slick/slick";

export class ImageSlider {
  constructor($el, conf) {
    this.$el = $el;
    let slickConf;

    // if in 70% column, use different defaults
    if(this.$el.parents('.container--30-70').length || this.$el.parents('.container--70-30').length) {
      slickConf = Object.assign(this.slickDefaults(2), conf);
    } else {
      slickConf = Object.assign(this.slickDefaults(3), conf);
    }

    // bind event prior to slick init
    this.bindEvents();
    this.$el.slick(slickConf);
  }

  bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on("mouseleave", ".slick-next, .slick-prev", event => {
      event.currentTarget.blur();
    });
  }

  slickDefaults(numSlides) {
    return {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: numSlides,
      slidesToScroll: numSlides,
      arrows: true,
      prevArrow: `
        <div class="slick-prev" tabindex="0">
            <div class="icon"><svg><use xlink:href="/assets/build/img/svg-sprite.svg#chevron-left"></use></svg></div>
        </div>`,
      nextArrow: `
        <div class="slick-next" tabindex="0">
           <div class="icon"><svg></sbg><use xlink:href="/assets/build/img/svg-sprite.svg#chevron-right"></use></svg></div>
        </div>`,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: numSlides - 1,
            slidesToScroll: numSlides - 1
          }
        },
        {
          breakpoint: 910,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  }
}
