import * as R from "ramda";
import {format} from "d3-format";

export const customFormat = (_, specifier) => {
  let val = _;
  let suffix = "";
  const type = R.last(specifier);

  let specCopy = specifier;
  if(type === 'm' || type === 'M'){
    specCopy = specCopy.slice(0, specCopy.length - 1) + 'f';
    if(Math.abs(val) >= 1e9){
      val = val/1e9;
      suffix = type === 'm' ? "B" : " billion";
    }
    else if(Math.abs(val) >= 1e6){
      val = val/1e6;
      suffix = type === 'm' ? "M" : " million";
    }
    else if(Math.abs(val) >= 1e3){
      val = val/1e3;
      suffix = type === 'm' ? "k" : " thousand";
    }
  }
  return format(specCopy)(val) + suffix;
};
