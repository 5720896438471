import $ from "jquery";

export class EmailDecrypt {
	constructor($emailLinks, $emailTexts) {

		var _this = this;
		$emailLinks.map(function(link) {
			_this.decryptHref(link);
		});

		$emailTexts.map(function(text) {
			_this.decryptText(text);
		});
  }

	decryptHref(link) {
		var encryptedEmail = $("<div/>").html($(link).attr('href')).text().replace('mailto:', '').split('?');
		$.get("/api/Decrypt", { input: encryptedEmail[0] }, function(data) {

			var translatedData = $("<div/>").html(data).text();
			if (encryptedEmail.length > 1) {
				translatedData += "?" + encryptedEmail[1];
			}

			$(link).attr('href', 'mailto:' + translatedData);
		});
	}

	decryptText(text) {
		$.get("/api/Decrypt", { input: text.innerHTML }, function(data) {

			var translatedData = $("<div/>").html(data).text();
			text.innerHTML = translatedData;

			$(text).removeClass('js-email-text');
		});
	}
}
