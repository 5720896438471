import $ from "jquery";
import R from "ramda";
import { RESULTS_DISPLAY_APPEND } from "./resultsDisplay";

const newResults = R.curry((newResults, oldResults, strategy) => {
  return strategy === RESULTS_DISPLAY_APPEND
    ? R.concat(oldResults, newResults)
    : newResults;
});

export function fetchSearchResults(url, query) {
    return $.ajax({
        url: url,
        data: query
    });
}

export function mapResponseToState(response, state) {
  const request = response.request;
  const castToInt = R.curry(parseInt)(R.__, 10);
  return R.evolve({
      totalResults: () => parseInt(response.totalResults, 10),
      results: newResults(response.results, R.__, state.resultsDisplayStrategy),
      facets: () => response.facets,
      query: R.merge(R.__, {
          pageId: request.pageId,
          page: castToInt(request.page),
          perPage: castToInt(request.perPage),
          sortBy: request.sortBy,
          sortOrder: request.sortOrder,
          loadAllPages: false
      })
  }, state);
}
