import R from "ramda";

export default (config) => {

  const {
    type,
    groupProp,
    subGroupProp,
    groupType,
    yAxisLowerBound,
    yAxisUpperBound,
    colorSchemeOverride
  } = config;

  const yScaleDomainField = (type === "bar" && groupType === "Stacked") ? "y1" : "Value";

  let yScaleDomainOverrides = {};
  if(!R.isNil(yAxisLowerBound)){
    yScaleDomainOverrides.domainMin = yAxisLowerBound;
  }
  if(!R.isNil(yAxisUpperBound)){
    yScaleDomainOverrides.domainMax = yAxisUpperBound;
  }

  const colorScheme = !R.isNil(colorSchemeOverride) && colorSchemeOverride !== "" ?
    colorSchemeOverride.split(",").map(s => s.trim()) :
    [
      "#314a8e",
      "#75b9b9",
      "#b51454",
      "#a58328",
      "#81588f"
    ];

  return {
    yScale: Object.assign({}, {
      "name": "yscale",
      "type": "linear",
      "domain": (type === "bar" && groupType === "Stacked") ?
        {"data": "table-stacked", "field": "y1"} :
        {"data": "table", "field": "Value"},
      "range": "height",
      "round": true,
      "zero": true,
      "nice": true
    }, yScaleDomainOverrides),
    barXScale: {
      "name": "xscale",
      "type": "band",
      "domain": {"data": "table", "field": groupProp},
      "range": "width",
      "paddingOuter": 0.2,
      "paddingInner": 0.4
    },
    trendXScale: {
      "name": "xscale",
      "type": "point",
      "domain": {"data": "table", "field": groupProp},
      "range": "width",
      "padding": 0.5
    },
    colorScale: {
      "name": "color",
      "type": "ordinal",
      "domain": {"data": "table", "field": subGroupProp},
      "range": colorScheme
    },
    hoverXScale: {
      "name": "fullwidth-xscale",
      "type": "band",
      "domain": {"data": "table", "field": groupProp},
      "range": "width",
      "padding": 0
    }
  };
};
