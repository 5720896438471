import scales from "./scales.js";
import signals from "./signals.js";
import axes from "./axes.js";
import marks from "./marks.js";
import legend from "./legend.js";
import {stackedDataTransform, stackedDataSumTransform} from "./data.js";

export default (config, data) => {

  const {
    padding,
    width,
    height,
    type,
    groupProp,
    subGroupProp,
    dataFormat,
    includeGridLines,
    yAxisLabel,
    yAxisLowerBound,
    yAxisUpperBound,
    includeLegend,
    splitLegend,
    labelLines,
    emphasizeLines,
    colorSchemeOverride
  } = config;

  const {yScale, trendXScale, colorScale, hoverXScale} = scales(config);
  const {hoverSignal, toolTipSignal} = signals(config);
  const {yAxis, xAxis} = axes(config);
  const {twoColumnLegend, oneColumnLegend} = legend(config);
  const {interactiveAreas, backgroundAreas, trendLines, trendLabels} = marks(config);

  const dataThisChart = [
    data,
		{
      "name": "definedtable",
      "source": "table",
      "transform": [
        {
          "type": "filter",
          "expr": "datum.Value !== null"
        }
      ]
    },
    {
      "name": "subgroups",
        "source": "table",
        "transform": [
          {
            "type": "aggregate",
            "groupby": [subGroupProp]
          }
        ]
    },
    {
      "name": "groups",
      "source": "table",
      "transform": [
        {
          "type": "aggregate",
          "groupby": [groupProp]
        }
      ]
    },
    {
      "name": "latest",
      "source": "table",
      "transform": [
        {
          "type": "filter",
          "expr": `datum['${groupProp}'] === data('groups')[length(data('groups')) - 1]['${groupProp}']`
        }
      ]
    }
  ];

  const marksThisChart = [
    backgroundAreas,
    ...(trendLines),
    ...(includeLegend && splitLegend ?  [twoColumnLegend] :
      includeLegend ? [oneColumnLegend] :
      []),
    ...(labelLines && width > 600 ? [trendLabels] : []),
    interactiveAreas
  ];

  return {
    "$schema": "https://vega.github.io/schema/vega/v3.0.json",
    "padding": padding,
    "width": width,
    "height": height,
    "autosize": {
      "type": "fit",
      "contains": "padding"
    },
    "data": dataThisChart,
    "signals": [
      hoverSignal,
      toolTipSignal
    ],
    "scales": [
      yScale,
      trendXScale,
      colorScale,
      hoverXScale
    ],
    "axes": [
      yAxis,
      xAxis
    ],
    "marks": marksThisChart
  };
};
