import React from "react";
import { string, number, object, arrayOf, func } from "prop-types";
import FacetPill from "./FacetPill.jsx";


const propTypes = {
  resultsRange: string.isRequired,
  totalResults: number.isRequired,
  keywords: string,
  selectedFacets: arrayOf(object).isRequired,
  handleFacetDismiss: func.isRequired,
	dictionary: object.isRequired
};

const defaultProps = {
  selectedFacets: []
};

const SearchStatus = ({
  resultsRange,
  totalResults,
  keywords,
  selectedFacets,
  handleFacetDismiss,
	dictionary
}) => {

	const shouldRenderFacets = selectedFacets.length > 0;
  const shouldRenderKeywords = !!keywords;

  const onFacetClick = (value) => {
    handleFacetDismiss(value.id);
  };

  const resultLabel = totalResults === 1 ? dictionary.resultText : dictionary.resultsText;

  const renderSelectedFacets = () => {
    return (
      <div className="search-results__facet-pills">
          {selectedFacets.map( value => {
            return (
              <FacetPill key={value.id} value={value} onXClick={() => onFacetClick(value)} />
            );
          })}
      </div>
    );
  };

  return (
    <div className="search-results__status">
      <div>
          <span>{resultsRange} {dictionary.ofText} {totalResults} {resultLabel}</span>
          { shouldRenderKeywords && (
            <span>&nbsp;{dictionary.forText} &quot;{keywords}&quot;</span>
          )}
          { shouldRenderFacets && <span>&nbsp;{dictionary.withFiltersText}</span> }
      </div>
      { shouldRenderFacets && renderSelectedFacets() }
    </div>
  );

};



SearchStatus.propTypes = propTypes;

SearchStatus.defaultProps = defaultProps;

export default SearchStatus;
