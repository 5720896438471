import React from "react";
import $ from "jquery";
import { arrayOf, string, number, shape, object } from "prop-types";
import debounce from "lodash.debounce";

export default class ReportNav extends React.Component {

  static propTypes = {
    list: arrayOf(shape({
      id: number,
      name: string,
      domNode: object
    })).isRequired
  };

  state = {
    selectedItemId: 0
  }

  componentDidMount() {
    const $reportNavEl = $("#js-report-nav"),
          initReportNavScrollTop = $reportNavEl.offset().top,
          initReportNavLeft = -$reportNavEl.offset().left;

    // position nav
    $reportNavEl.css("left", initReportNavLeft);
    // $reportNavEl.css("visibility", "visible");
    
    // add scroll handler
    $(window).scroll(debounce(() => this.scrollHandler(initReportNavScrollTop, initReportNavLeft), 10));
  }

  componentWillUnmount() {
    // remove scroll handler
  }

  clickHandler(item) {
    //scroll to element
    $("html, body").animate({
      scrollTop: $(item.domNode).offset().top
    }, 500);
  }

  scrollHandler(initReportNavScrollTop, leftPosition) {
    const windowScrollTop = $(window).scrollTop(),
          $reportNavEl = $("#js-report-nav"),
          spacingFromTop = 200,
          reportNavScrollTop = initReportNavScrollTop - spacingFromTop,
          list = this.props.list,
          docHeight = $(document).height(),
          navHeight = $reportNavEl.outerHeight(),
          $footer = $('.global-footer');

    // check if top of the window hits the nav
    // if it does, make it sticky
    if (windowScrollTop >= ($footer.offset().top - navHeight - spacingFromTop)) {
      const reportNavTopPos = docHeight - initReportNavScrollTop - $footer.outerHeight() - navHeight;
      $reportNavEl.css("left", leftPosition);
      $reportNavEl.css("top", reportNavTopPos);
      $reportNavEl.css("position", "absolute");
    }
    else if (windowScrollTop >= reportNavScrollTop) {
      $reportNavEl.css("left", "0");
      $reportNavEl.css("top", "200px");
      $reportNavEl.css("position", "fixed");
    }
    else if (windowScrollTop < reportNavScrollTop) {
      $reportNavEl.css("left", leftPosition);
      $reportNavEl.css("top", "auto");
      $reportNavEl.css("position", "absolute");
    }

    let selectedItem = list.reduce(function(lowestPos, item) {
      if ((windowScrollTop + $(window).height() == $(document).height()) ||
          (windowScrollTop + 1 >= $(item.domNode).offset().top)) {
        // at the very bottom of the page or below/at a nav item
        return item;
      }
      else {
        return lowestPos;
      }
    }, {});

    if (selectedItem) {
      this.setState({ selectedItemId: selectedItem.id });
    }
  }

  renderListItem(item) {
    const classes = "report-nav__list-item " + (item.id === this.state.selectedItemId ? "is-selected" : "") + (" ");
    
    return (
      <li key={item.id}
        className={classes}
        onClick={() => this.clickHandler(item)}>
        <span className="report-nav__list-item-name">{item.name}</span>
      </li>
    )
  }

  render() {
    // on click, set state to id of selected item and add show class
    // scroll window to selected
    // as user scrolls, check height of window vs the positions of items
    return (
      <ul className="report-nav__list">
        {
          this.props.list.map(item => this.renderListItem(item))
        }
      </ul>
    );
  }
}
