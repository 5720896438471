import $ from "jquery";

const OPEN = "is-open";
const CLOSED = "is-closed";

export default class Dropdown {

    constructor($el) {

        // this.$el should be a <form>
        this.$el =  $el;
        this.$trigger = $el.find(".js-dropdown-trigger");
        this.$submenu = $el.find(".js-submenu");

        this.state = {
            openState: CLOSED
        };

        // only fire responsive behaviors when the breakpoint changes
        let throttler;
        $(window).on("resize", event => {
            // adjust the submenu alignment when the user stops resizing
            window.clearTimeout(throttler);
            throttler = setTimeout( this.adjustFlyoutMenuAlignment, 400);
        });

        this.adjustFlyoutMenuAlignment();

        // initialize with the state
        this.componentDidUpdate({}, this.state);
    }

    setState = (newState) => {

        // make a copy of the old state
        const oldState = Object.assign({}, this.state);
        // compute the new state and save it in this.state
        this.state = Object.assign({}, this.state, newState);
        // trigger an update with these values
        this.componentDidUpdate(oldState, this.state);
    }

    componentDidUpdate = (oldState, newState) =>{

        const { openState } = newState;

        this.$el.toggleClass("is-open", openState === OPEN);

        // remove all events!!
        $(document).off("click.dropdown", this.onClickAway);
        this.$trigger.off("click.dropdown", this.onOpenClick);


        // attach clickaway handler
        if (openState === OPEN){
            $(document).on("click.dropdown", this.onClickAway);
        }
        // attach open handler
        else if (openState === CLOSED) {
            this.$trigger.on("click.dropdown", this.onOpenClick);
        }

    }

    onClickAway = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // if we clicked outside of the submenu, close it
        if ($(e.target).closest(this.$submenu).length === 0){
            this.setState({ openState: CLOSED });
        }
    }

    adjustFlyoutMenuAlignment = () => {
        // if the menu is going to be rendered offscreen,
        // we need to push it back.
        const windowWidth = $(window).width();
        const submenuWidth = this.$submenu.width();
        const elPosition = this.$el.get(0).getBoundingClientRect();

        const overflowRight = (elPosition.left + submenuWidth) - windowWidth;

        // if it's overflowing, put it at the right edge
        // if it fits, left align it
        const newLeft = (overflowRight > 0)
            ? -10 - overflowRight
            : 0;

        this.$submenu.css({ left: `${newLeft}px` });
    }

    onOpenClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ openState: OPEN });
    }

}
