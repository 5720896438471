﻿import $ from 'jquery';

function calcOverflow(position){
  return position > 0 ? 0 : Math.abs(position);
}

export function showPopup(container, contents, x, y, offsets = {top: 0, right: 0, bottom: 0, left: 0}){
  if(container.find('.custom-hover').length === 0){

    // One for actually showing
    container.append($('<div/>')
      .addClass('custom-hover')
      .addClass('toshow'));

    // One for calculating size
    container.append($('<div/>')
      .addClass('custom-hover')
      .addClass('tohide'));
  }

  const hoverEl = container.find('.custom-hover.toshow');
  const hoverElCalculate = container.find('.custom-hover.tohide');

  // Set the html, opacity 0 (to hide, but still render size), and move to top left
  hoverElCalculate
    .show()
    .html(contents);

  // Calculate position
  const hoverRect = hoverElCalculate[0].getBoundingClientRect();
  const hoverHeight = Math.ceil(hoverRect.height);
  const hoverWidth = Math.ceil(hoverRect.width);

  const boundHoverHeight = hoverElCalculate.outerHeight();
  const boundHoverWidth = hoverElCalculate.outerWidth();

  const scrollTop = $(document).scrollTop();
  const scrollLeft = $(document).scrollLeft();
  const containerOffset = container.offset();

  const windowTopRelativeToContainer = -containerOffset.top + scrollTop;
  const windowBottomRelativeToContainer = -containerOffset.top + scrollTop + window.innerHeight;

  const windowLeftRelativeToContainer = -containerOffset.left + scrollLeft;
  const windowRightRelativeToContainer = -containerOffset.left + scrollLeft + window.innerWidth;

  const xPositions = {
    left: x - hoverWidth - offsets.left,
    right: x + offsets.right,
    middle: x - (hoverWidth/2)
  };

  const yPositions = {
    top: y - hoverHeight - offsets.top,
    bottom: y + offsets.bottom,
    middle: y - (hoverHeight/2)
  };

  const xOverflows = {
    left: calcOverflow(xPositions.left - windowLeftRelativeToContainer),
    right: calcOverflow(windowRightRelativeToContainer - (xPositions.right + hoverWidth)),
    middle:
      calcOverflow(xPositions.middle - windowLeftRelativeToContainer) +
      calcOverflow(windowRightRelativeToContainer - (xPositions.middle + hoverWidth))
  };

  const yOverflows = {
    top: calcOverflow(yPositions.top - windowTopRelativeToContainer),
    bottom: calcOverflow(windowBottomRelativeToContainer - (yPositions.bottom + hoverHeight)),
    middle:
      calcOverflow(yPositions.middle - windowTopRelativeToContainer) +
      calcOverflow(windowBottomRelativeToContainer - (yPositions.middle + hoverHeight))
  };

  const possiblePositions = [
    {
      key: 'top',
      overflow: xOverflows.middle + yOverflows.top,
      top: yPositions.top,
      left: xPositions.middle
    },
    {
      key: 'bottom',
      overflow: xOverflows.middle + yOverflows.bottom,
      top: yPositions.bottom,
      left: xPositions.middle
    },
    {
      key: 'right',
      overflow: xOverflows.right + yOverflows.middle,
      top: yPositions.middle,
      left: xPositions.right
    },
    {
      key: 'left',
      overflow: xOverflows.left + yOverflows.middle,
      top: yPositions.middle,
      left: xPositions.left
    },
    {
      key: 'bottom-right',
      overflow: xOverflows.right + yOverflows.bottom,
      top: yPositions.bottom,
      left: xPositions.right
    },
    {
      key: 'bottom-left',
      overflow: xOverflows.left + yOverflows.bottom,
      top: yPositions.bottom,
      left: xPositions.left
    },
    {
      key: 'top-right',
      overflow: xOverflows.right + yOverflows.top,
      top: yPositions.top,
      left: xPositions.right
    },
    {
      key: 'top-left',
      overflow: xOverflows.left + yOverflows.top,
      top: yPositions.top,
      left: xPositions.left
    }
  ].sort((a, b) => b.overflow > a.overflow ? -1 : a.overflow > b.overflow ? 1 : 0);

  // Pick the best position
  const position = possiblePositions[0];

  hoverEl
    .attr('class', 'custom-hover toshow ' + position.key)
    .css('width', boundHoverWidth)
    .css('height', boundHoverHeight)
    .css('left', position.left + 'px')
    .css('top', position.top + 'px')
    .html(contents)
    .addClass('show');
}

export function hidePopup(container){
  container.find('.custom-hover.toshow').removeClass('show');
}
