import React from "react";
import { string } from "prop-types";

export default class AuthorItem extends React.Component {

  static propTypes = {
    name: string.isRequired,
    url: string,
    title: string,
    organization: string
  };

  render() {
    const { name, url, title, organization } = this.props;

    return (
      <span className="search-item__author">
        { url ? <a href={url}>{name}</a> : name }
        { title ? ", " + title : null }
        { organization ? ", " + organization : null }
      </span>
    );
  }
}
