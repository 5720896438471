import $ from "jquery";
import "jquery-modal";

export class InlineVimeoVideo {
  constructor(videos) {

    function onPlayerReady(video, i) {
      var parent = $(video);
      parent.attr('href','');
      //var videoId = parent.data('videoId');
      //var defaultTrigger = parent.find('.js-video-trigger');
      var specificTrigger = 'inline-vimeo-video-'.concat(i);
      parent.addClass(specificTrigger);
      //var trigger = parent.find('.'.concat(specificTrigger));
      var modalId = 'inline-vimeo-video-modal_'.concat(i);
      parent.find('iframe:not(.initialized)').addClass('initialized').wrap(function () {
        return '<div id=' + modalId + ' style="display:none;"><div class="iframe-container">' + $(this).text() + '</div></div>'
      })
      $("#inline-vimeo-video-".concat(i)).removeAttr("style","");
      // hide overlay and play video on click
      parent.on('click', function (e) {
        e.preventDefault();
        $('#' + modalId).modal();
      });
    }

    videos.map(function (video, i) {
      var vimeoLink = $(video).attr('href');
      if (vimeoLink) {
        var videoId = 'inline-vimeo-video-'.concat(i);
        var videoContainer = $('<iframe/>', { 'id': videoId, 'src': vimeoLink, 'style': 'display:none;', "frameborder": "0", "allow": "autoplay; fullscreen", "allowfullscreen": "" });
        $(video).append(videoContainer);
        var player = new Vimeo.Player($('#' + videoId)[0]);
        player.ready().then(
          onPlayerReady(video, i)
        );
      }
    });
  }
}
