import React from "react";
import { func, shape, string } from "prop-types";

const propTypes = {
  value: shape({
    id: string,
    name: string
  }).isRequired,
  onXClick: func.isRequired
};

const FacetPill = ({ onXClick, value }) => {

  return (
    <div key={value.id} className="search-results__facet-pill">
      <span>{value.name}</span>
      <button onClick={onXClick}>
        <svg className="icon">
          <use xlinkHref="/assets/build/img/svg-sprite.svg#close"></use>
        </svg>
      </button>
    </div>
  );
}

FacetPill.propTypes = propTypes;

export default FacetPill;
