export default (config) => {

  const {
    groupProp,
    subGroupProp,
  } = config;

  return {
    hoverSignal: {
      "name": "activeGroup",
      "value": null,
      "on": [
        { "events": "@interactive-areas:mousemove", "update": `datum['${groupProp}']`, "force": true },
        { "events": "@interactive-areas:touchstart", "update": `datum['${groupProp}']`, "force": true },
        { "events": "@interactive-areas:mouseout", "update": "null" }
      ]
    },
    toolTipSignal: {
      "name": "tooltip",
      "value": null,
      "update": `activeGroup? 
        { 
          group: activeGroup, 
          data: data('table'), 
          colors: {domain: domain('color'), range: range('color')}
        } : 
        null`
    }
  };
};
