export default (config) => {

  const {
    width,
    type,
    groupProp,
    subGroupProp,
    dataFormat,
    thresholdLineValue,
    thresholdLineLabel,
    emphasizeLines
  } = config;

  const emphasizedLines = (emphasizeLines || "").split(",").map(s => s.trim());

  const legendSpec = split => ({
    "type": "group",
    "name": "legend",
    "signals": [
      {
        "name": "range",
        "update": (split?
          "sequence(0, length(data('subgroups')), 0.5)" :
          "sequence(0, length(data('subgroups')), 1)")
      },
      {"name": "emphasizedLines", "value": emphasizedLines}
    ],
    "scales": [
      {
        "name": "pos",
        "type": "ordinal",
        "range": {
          "signal": "range"
        },
        "domain": {"data": "subgroups", "field": subGroupProp}
      }
    ],
    "encode": {
      "enter": {
        "y": {"signal": "height + padding.bottom + 50"}
      }
    },
    "marks": [
      (type === "trend" ? {
        "type": "rule",
        "from": {"data": "subgroups"},
        "encode": {
          "enter": {
            "x": { "signal": (split?
              `scale('pos', datum['${subGroupProp}']) % 1 === 0 ? 0 : width/2` :
              "0" )},
            "x2": { "signal": (split?
              `12 + (scale('pos', datum['${subGroupProp}']) % 1 === 0 ? 0 : width/2)` :
              "12" )},
            "y": { "signal": `floor(scale('pos', datum['${subGroupProp}'])) * 25` },
            "stroke": { "scale": "color", "field": subGroupProp },
            "strokeCap": {"value": "round"},
            "strokeWidth": [
              {
                "test": "indexof(emphasizedLines, datum.Series) >= 0",
                "value": 7
              },
              {"value": 4}
            ]
          }
        }
      } : {
        "type": "symbol",
        "from": {"data": "subgroups"},
        "encode": {
          "enter": {
            "x": { "signal": (split?
              `scale('pos', datum['${subGroupProp}']) % 1 === 0 ? 0 : width/2` :
              "0" )},
            "y": { "signal": `floor(scale('pos', datum['${subGroupProp}'])) * 25` },
            "fill": { "scale": "color", "field": subGroupProp },
            "shape": { "value": "square"},
            "size": { "value": 256 }
          }
        }
      }),
      {
        "type": "text",
        "from": {"data": "subgroups"},
        "encode": {
          "enter": {
            "text": {"field": subGroupProp},
            "baseline": {"value": "middle"},
            "x": { "signal": (split?
              `20 + (scale('pos', datum['${subGroupProp}']) % 1 === 0 ? 0 : width/2)` :
              "20")},
            "y": { "signal": `floor(scale('pos', datum['${subGroupProp}'])) * 25` },
            "fill": {"value": "#6c6c69"},
            "fontSize": (width < 500 ? {"value": 13} : {"value": 15}),
            "font": {"value": "stevie-sans, sans-serif"},
            "fontWeight": {"value": 400},
          }
        }
      }
    ]
  });

  return {
    twoColumnLegend: legendSpec(true),
    oneColumnLegend: legendSpec(false)
  };
};
