import $ from "jquery";
import "jquery-modal";

export class PdfModal {
    constructor(pdfs) {
        pdfs.map(function (pdf, i) {
            var pdfLink = $(pdf).attr('href');
            if (pdfLink) {
                var pdfId = 'inline-pdf-'.concat(i);
                var pdfContainer = $('<iframe/>', { 'id': pdfId, 'src': pdfLink, 'style': 'display:none;', "frameborder": "0" });
                $(pdf).append(pdfContainer);
                var parent = $(pdf);
                parent.attr('href', '');
                var specificTrigger = 'inline-pdf-'.concat(i);
                parent.addClass(specificTrigger);
                var modalId = 'inline-pdf-modal_'.concat(i);
                parent.find('iframe:not(.initialized)').addClass('initialized').wrap(function () {
                    return '<div id=' + modalId + ' style="display:none;" class="pdf-modal"><div class="iframe-container">' + $(this).text() + '</div></div>'
                })
                $("#inline-pdf-".concat(i)).removeAttr("style", "");
                parent.on('click', function (e) {
                    e.preventDefault();
                    $('#' + modalId).modal();
                });
            }
        });
    }
}
