import { View, parse, Info, Debug, None } from "vega";
import $ from "jquery";
import * as R from 'ramda';
import buildBarSpec from "./spec/buildBarSpec.js";
import buildTrendSpec from "./spec/buildTrendSpec.js";
import {registerBarPopup, registerTrendPopup} from "./popup/popups.js";
import {functionContext} from 'vega-parser';
import {customFormat} from "./format/format.js";
import detectCsv from 'detect-csv';

functionContext.format = customFormat;

const barParse = {
  "Group": "string",
  "Subgroup": "string",
  "Value": "number",
  "Sublabel": "string"
};

const trendParse = {
  "Timeframe": "string",
  "Series": "string",
  "Value": "number",
  "Label": "string",
  "Note": "string"
};

const buildDataSpec = R.curry((parse, rawData) => {

  const fmt = detectCsv(rawData);

  return {
    "name": "table",
    "values": rawData,
    "format": {
      "type": fmt && fmt.delimiter === '\t' ? "tsv" : "csv",
      "parse": parse
    }
  };
});

const buildBarDataSpec = buildDataSpec(barParse);
const buildTrendDataSpec = buildDataSpec(trendParse);

const resolveContainers = container => {
  let chartContainer = $(".chart", container);
  let popupContainer = $(".popup", container);

  if(chartContainer.length === 0){
    chartContainer = container.append($("<div/>").addClass("chart"));
  }
  if(popupContainer.length === 0){
    popupContainer = container.append($("<div/>").addClass("popup"));
  }

  return {
    chartContainer,
    popupContainer
  };
};

function renderBarSpec(container, options, data){

	function render(){  
		const {chartContainer, popupContainer} = resolveContainers(container);

  const padding = {"top": 20, "left": 20, "right": 20, "bottom": 30};
  const width = container.innerWidth();
  const height = width < 500 ? width * 0.8 : width * 0.66;

  const spec = buildBarSpec(Object.assign({}, options, {width, height, padding}), buildBarDataSpec(data));

  // Render the view
  const view = new View(parse(spec), {
    functions: functionContext
  })
    .renderer('svg')
    .initialize(chartContainer[0])
    .hover();

		view.logLevel(None);

		view.run();

		registerBarPopup(spec, options, popupContainer, view);
	}

	$(window).on('resize', render);
	render();
}

function renderTrendSpec(container, options, data){

	function render(){  
		const {chartContainer, popupContainer} = resolveContainers(container);

  const padding = {"top": 20, "left": 20, "right": 20, "bottom": 20};
  const width = container.innerWidth();
  const height = width < 500 ? width * 0.8 : width * 0.66;

  const spec = buildTrendSpec(Object.assign({}, options, {width, height, padding}), buildTrendDataSpec(data));

  // Render the view
  const view = new View(parse(spec), {
    functions: functionContext
  })
    .renderer('svg')
    .initialize(chartContainer[0])
    .hover();

		view.logLevel(None);

		view.run();

		registerTrendPopup(spec, options, popupContainer, view);
	}

	$(window).on('resize', render);
	render();
}

export function renderChart(container){
  const chartType = container.attr("data-type");
	const options = JSON.parse($(".chart-options", container).val());
	const data = $(".chart-data", container).val();

	if(chartType === "bar"){
		renderBarSpec(container, options, data);
	}
	else if(chartType === "trend"){
		renderTrendSpec(container, options, data);
	}
	else {
		console.log("ERROR: Chart type not defined in data-type attribute");
	}
}

function elColspan(el){
	const colspanAttr = el.attr("colspan");
	return colspanAttr ? +colspanAttr : 1;
}
 
function headerCellsText(table){

	let headerCellText = [];

	table.find("thead tr").each(function(){
		
		let thisRowText = [];
		let index = 0;

		$(this).find("th").each(function(){
			const colspan = elColspan($(this));
			const $th = $(this);
			R.range(index, index + colspan).forEach(function(i){
				thisRowText.push($th.text());
			});

			index += colspan;
		});
		headerCellText.push(thisRowText);
	});
 return headerCellText;
}

export function preProcessResponsiveTable(container){
	const indexedHeaderCellsText = headerCellsText(container);

	if(indexedHeaderCellsText.length === 0){
		return;
	}

	const lastHeaderRow = R.last(headerCellsText(container));

	container.find('table').addClass('has-header');
	container.find("tbody tr").each(function(){
		const $tr = $(this);
		let index = 0;
		$tr.find("td").each(function(){
			const $td = $(this);
			const colspan = elColspan($td);
			const headerText = R.range(index, index + colspan).map(i => lastHeaderRow[i]);
			$td.attr("data-label", headerText.join(", "));
			index += colspan;
	  });
	});
}