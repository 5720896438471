export default (config) => {

  const {
    width,
    type,
    groupProp,
    subGroupProp,
    yAxisLabel,
		axisDataFormat,
    includeGridLines
  } = config;

  const axisLabelProps = {
    "fill": {"value": "#6c6c69"},
    "fontSize": width < 500 ? {"value": 11}: {"value": 13},
    "font": {"value": "stevie-sans, sans-serif"},
    "fontWeight": {"value": 500}
  };

  const axisTickProps = {
    "stroke": {"value": "#d1d1d0"},
    "strokeWidth": {"value": 1}
  };

  return {
    yAxis: Object.assign({}, {
      "orient": "left",
      "scale": "yscale",
      "grid": width > 500 && includeGridLines,
      "domain": !includeGridLines,
      "ticks": !includeGridLines,
      "tickSize": 8,
      "labelOverlap": true,
      "tickCount": 7,
      "labelPadding": 10,
      "encode": {
        "labels": {
          "enter": axisLabelProps,
          "update": {
            "text": {"signal": `format(datum.value, '${axisDataFormat}')`}
          }
        },
        "title": {
          "enter": axisLabelProps
        },
        "grid": {
          "enter": Object.assign({}, {
            "strokeOpacity": {"value": 0.6},
          }, axisTickProps)
        },
        "ticks": {
          "enter": axisTickProps
        },
        "domain": {
          "enter": axisTickProps
        }
      }
    }, yAxisLabel ? {
      title: yAxisLabel,
      titlePadding: 15
    }: {}),
    xAxis: {
      "orient": "bottom",
      "scale": "xscale",
      "ticks": type === "trend",
      "tickSize": 10,
      "labelOverlap": true,
      "labelPadding": width > 500 ? 15 : 10,
      "encode": {
        "labels": {
          "enter": axisLabelProps
        },
        "domain": {
          "enter": axisTickProps
        },
        "ticks": {
          "enter": axisTickProps
        }
      }
    },
    manualXAxis: {
      "type": "group",
      "role": "axis",
      "marks": [
        {
          "type": "rule",
          "encode": {
            "enter": Object.assign({}, {
              "x": {"value": 0},
              "x2": {"signal": "width"},
              "y": {"scale": "yscale", "value": 0},
              "y2": {"scale": "yscale", "value": 0}
            }, axisTickProps)
          }
        },
        {
          "type": "text",
          "from": {"data": "table"},
          "encode": {
            "enter": Object.assign({}, {
              "x": {"scale": "xscale", "field": groupProp},
              "dx": {"scale": "xscale", "band": 0.5},
              "y": {"scale": "yscale", "value": 0},
              "dy": [
                {
                  "test": "datum.Value < 0",
                  "value": width > 500 ? -15 : -10
                },
                {"value": width > 500 ? 15 : 10},
              ],
              "baseline": [
                {
                  "test": "datum.Value < 0",
                  "value": "bottom"
                },
                {"value": "top"},
              ],
              "align": {"value": "center"},
              "text": {"field": groupProp}
            }, axisLabelProps)
          }
        }
      ]
    }
  };
};


