import $ from "jquery";
import { breakpoint } from "../breakpoint";

const OPEN = "is-open";
const ALWAYS_OPEN = "is-always-open";
const CLOSED = "closed";

export default class SearchBox {
  constructor($el) {
    // this.$el should be a <form>
    this.$el = $el;
    this.$open = this.$el.find(".js-search-open");
    this.$close = this.$el.find(".js-search-close");
    this.$input = this.$el.find("input[type='text']");

    this.state = {
      openState: CLOSED,
      breakpoint: breakpoint()
    };

    // this action will always be the same
    this.$close.on("click.search-box", this.onCloseClick);

    // only fire responsive behaviors when the breakpoint changes
    $(window).on("resize", event => {
      if (this.state.breakpoint !== breakpoint()) {
        this.setState({ breakpoint: breakpoint() });
      }
    });

    // initialize with the state
    this.componentDidUpdate({}, this.state);
  }

  setState = newState => {
    // make a copy of the old state
    const oldState = Object.assign({}, this.state);
    // compute the new state and save it in this.state
    this.state = Object.assign({}, this.state, newState);
    // trigger an update with these values
    this.componentDidUpdate(oldState, this.state);
  };

  componentDidUpdate = (oldState, newState) => {
    const { openState, breakpoint } = newState;

    this.$el.toggleClass("is-open", openState === OPEN);
    this.$el.toggleClass("is-always-open", openState === ALWAYS_OPEN);

    // remove all events!!
    $(document).off("click.search-box", this.onClickAway);
    this.$open.off("click.search-box", this.onOpenClick);

    // attach clickaway handler
    if (openState === OPEN) {
      $(document).on("click.search-box", this.onClickAway);
      this.$input.focus();
    }
    // attach open handler
    else if (openState === CLOSED) {
      this.$open.on("click.search-box", this.onOpenClick);
    }
  };

  onClickAway = e => {
    e.preventDefault();
    e.stopPropagation();

    // if we clicked outside, close the search
    if ($(e.target).closest(this.$el).length === 0) {
      this.setState({ openState: CLOSED });
    }
  };

  onOpenClick = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ openState: OPEN });
  };

  onCloseClick = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ openState: CLOSED });
  };
}
