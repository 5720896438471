import $ from "jquery";

export class BackToTop {
  constructor($el, $primaryNavEl) {
    this.$el =  $el;
    this.$primaryNavEl = $primaryNavEl;

    this.bindEvents();
  }

  bindEvents() {

	  // watch the scroll for the "back to top" button
	  $(document).on("scroll", event => this.onScroll(event));

	  this.$el.on("click", event => this.onBackToTopClick(event));

  }

  onScroll(event) {

    // if there is a primary nav, show the back to top button if the primaryNav is off the screen
    // otherwise show it if the scroll is kinda down the page
    const headerIsVisible = (this.$primaryNavEl)
      ? (this.$primaryNavEl.offset().top - $(window).scrollTop() + this.$el.height()) > 0
      : $(window).scrollTop() < 300;

		// hide the back to top button if the header is visible
    this.$el.toggleClass("is-visible", !headerIsVisible);
  }

  onBackToTopClick(event) {
    // scroll to top
    $("html, body").animate({ scrollTop: 0 });
  }
}
