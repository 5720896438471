import $ from "jquery";
import "jquery-modal";

export class VimeoVideo {
  constructor(videos) {

    function onPlayerReady(video, i) {
      var parent = $(video);
      var videoId = parent.data('videoId');
      var defaultTrigger = parent.find('.js-video-trigger');
      var specificTrigger = 'js-video-trigger-'.concat(i);
      defaultTrigger.addClass(specificTrigger);
      var trigger = parent.find('.'.concat(specificTrigger));
      var modalId = 'js-video-modal__'.concat(videoId).concat('_').concat(i);
      parent.find('iframe:not(.initialized)').addClass('initialized').wrap(function () {
        return '<div id=' + modalId + '><div class="iframe-container">' + $(this).text() + '</div></div>'
      })
      // hide overlay and play video on click
      trigger.on('click', function () {
        $('#' + modalId).modal();
      });
    }

    videos.map(function (video, i) {
      var videoIFrame = $(video).find(".js-video-wrapper");
      var player = new Vimeo.Player(videoIFrame[0]);
      player.ready().then(
        onPlayerReady(video, i)
      );
      return player;
    });
  }
}
