import React from "react";
import moment from "moment";
import { arrayOf, string, shape, bool } from "prop-types";
import AuthorItem from "./AuthorItem.jsx";

export default class ResultItem extends React.Component {

  static propTypes = {
    contentType: string,
    topic: string,
    date: string.isRequired,
    authors: arrayOf(shape({
      name: string.isRequired,
      url: string,
      title: string
    })),
    title: string.isRequired,
    body: string.isRequired,
    contentUrl: string.isRequired,
    displayUrl: string.isRequired,
		displayUrlClass: string,
    imageSrc: string,
		isSquareImage: bool,
    videoUrl: string,
    reportUrl: string,
		dictionary: shape({
			watchVideoText: string
    }),
    display: shape({
      topics: bool,
      contentType: bool,
      readMore: bool
    })
  }

  componentWillMount() {
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM Do, YYYY");
  }

  formatEyebrow(contentType, topic) {
    var eyebrow = "";

    if(this.props.display.contentType) {
      eyebrow = contentType;
    }

    if(this.props.display.topics) {
      if(eyebrow !== "" && topic !== "" && topic !== null) {
        eyebrow += " | ";
      }

      eyebrow += topic;
    }

    return eyebrow;
  }

  render() {
    const { title, body, contentType, topic, date, authors, imageSrc, isSquareImage, videoUrl, reportUrl, displayUrl, displayUrlClass, contentUrl, dictionary, display } = this.props;

    return (
      <article className="search-item" ref={ el => this.itemWrapper = el }>
          { imageSrc ? <figure className="search-item__media"><a href={contentUrl}><img src={imageSrc} className={isSquareImage ? "u-rounded-image" : "" } /></a></figure> : null }
            <div className="list-item__content">
            <header className="list-item__header">
              { this.formatEyebrow(contentType, topic) ? <h2 className="search-item__eyebrow">{this.formatEyebrow(contentType, topic)}</h2> : null }
              <h3 className="search-item__title" id={title + "?-a590c06a"}><a href={contentUrl}>{title}</a></h3>
            </header>
            <aside className="search-item__meta">
              { date ? <span className="search-item__date">{this.formatDate(date)}</span> : null }
              <span className="search-item__byline">
                { authors.map( (item, i) => <AuthorItem key={i} {...item} />) }
              </span>
              {videoUrl && (
                <div className="search-item__play-video">
                    <a href={videoUrl} target="_blank">
                      <svg className="svg-play-video">
                        <use xlinkHref="/assets/build/img/svg-sprite.svg#play-video"></use>
                      </svg>
                      <span>{dictionary.watchVideoText}</span>
                    </a>
                </div>
              )}
            </aside>
            <p className="search-item__description">{body}</p>
	          { display.readMore ? <a href={reportUrl ? reportUrl : contentUrl } className={"search-item__cta " + (displayUrlClass ? displayUrlClass : "arrow-link")} id={title + "?-a590c06b"}>{displayUrl}</a> : null}
            {contentType.toLowerCase() === "report" && (
                    <div className="sharebarInline">
                        <span className="sharebar__text">SHARE</span>
                        <div className="addthis_inline_share_toolbox"
                             data-url={reportUrl ? window.location.origin + reportUrl : window.location.origin + contentUrl}
                             data-title={title}
                             data-description={body}
                             data-image={window.location.origin + imageSrc}>
                        </div>
                    </div>
                )}
          </div>
      </article>
    );
  }
}
