import R from "ramda";
import scales from "./scales.js";
import signals from "./signals.js";
import axes from "./axes.js";
import marks from "./marks.js";
import legend from "./legend.js";
import {stackedDataTransform, stackedDataSumTransform} from "./data.js";

export default (config, data) => {

  const {
    padding,
    width,
    height,
    type,
    groupProp,
    subGroupProp,
    groupType,
    dataFormat,
    includeYAxis,
    includeGridLines,
    yAxisLabel,
    yAxisUpperBound,
    includeLegend,
    splitLegend,
    thresholdLineValue,
    thresholdLineLabel,
    colorSchemeOverride
  } = config;

  const {yScale, barXScale, colorScale, hoverXScale} = scales(config);
  const {hoverSignal, toolTipSignal} = signals(config);
  const {yAxis, xAxis, manualXAxis} = axes(config);
  const {twoColumnLegend, oneColumnLegend} = legend(config);
  const {groupedBars, simpleBars, simpleBarLabels, simpleBarSubLabels,
    stackedBars, stackedBarLabels, stackedBarSubLabels, stackedOverallBarLabels,
    interactiveAreas, backgroundAreas, threshold} = marks(config);

  const dataThisChart = [
		data,
    ...(groupType === "Stacked" ?
      [{
        "name": "table-stacked",
        "source": "table",
        "transform": [
          {
            "type": "stack",
            "groupby": [groupProp],
            "sort": {"field": subGroupProp, "order": "ascending"},
            "field": "Value"
          }
        ]
      }, {
        "name": "table-stacked-sum",
        "source": "table-stacked",
        "transform": [
          {
            "type": "aggregate",
            "groupby": [groupProp],
            "fields": ["Value"],
            "ops": ["sum"],
            "as": ["sum"]
          }
        ]
      }] :
      []),
    {
      "name": "subgroups",
        "source": "table",
        "transform": [
          {
            "type": "aggregate",
            "groupby": [subGroupProp]
          }
        ]
    },
    {
      "name": "groups",
      "source": "table",
      "transform": [
        {
          "type": "aggregate",
          "groupby": [groupProp]
        }
      ]
    }
  ];

  const barMarks = 
    groupType === "Grouped" ? [groupedBars] :
    groupType === "Stacked" ? [
      ...[stackedBars],
      ...(width > 500? [
        stackedBarLabels,
        stackedBarSubLabels,
        stackedOverallBarLabels
      ] : [])
    ] :
    [
      ...[simpleBars],
      ...(width > 500? [
        simpleBarLabels,
        simpleBarSubLabels
      ]: [])
    ];

  const marksThisChart = [
    // Background highlight for interactivity
    backgroundAreas,
    // All marks for the bars
    ...barMarks,
    // Manual axis for simple bar charts
    ...(groupType !== "Grouped" && groupType !== "Stacked" ? 
      [manualXAxis] :
      []),
    // Threshold
    ...(!R.isNil(thresholdLineValue)? [threshold] : []),
    // Legend
    ...(includeLegend && splitLegend ?  [twoColumnLegend] :
      includeLegend ? [oneColumnLegend] :
      []),
    // Hover targets
    interactiveAreas
  ];

  return {
    "$schema": "https://vega.github.io/schema/vega/v3.0.json",
    "padding": padding,
    "width": width,
    "height": height,
    "autosize": {
      "type": "fit",
      "contains": "padding"
    },
    "data": dataThisChart,
    "signals": [
      hoverSignal,
      toolTipSignal
    ],
    "scales": [
      yScale,
      barXScale,
      colorScale,
      hoverXScale
    ],
    "axes": [
      ...(includeYAxis? [yAxis] : []),
      ...(groupType === "Grouped" || groupType === "Stacked" ?
        [xAxis] : [])
    ],
    "marks": marksThisChart
  };
};
