import React from "react";
import { string, func, shape, bool } from "prop-types";
import LoadingSpinner from "./LoadingSpinner.jsx";


export default class SearchBox extends React.Component {

  static propTypes = {
    keywords: string,
    handleEntry: func.isRequired,
    loading: bool,
    dictionary: shape({
      searchBoxPlaceholder: string,
      searchBoxLabel: string
    }).isRequired
  };

  state = {
    initiatedLoad: false
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) {
      this.setState({ initiatedLoad: false });
    }
  }

  handleSubmit(event) {
      event.preventDefault();
      this.props.handleEntry(this.keywordInput.value);
      this.setState({ initiatedLoad: true });
  }

  render() {
    const { dictionary, keywords, loading } = this.props;
    const shouldShowSpinner = this.state.initiatedLoad && loading;

    return (
      <label className="search-keyword">
        <div className="search-facets__label">{dictionary.searchBoxLabel}</div>
        <form onSubmit={ e => this.handleSubmit(e) } noValidate>
          <div className="search-keyword__input">
            <input type="text"
              placeholder={dictionary.searchBoxPlaceholder}
              defaultValue={keywords || ""}
              ref={ input => this.keywordInput = input }
            />
	        <svg className="icon" role="img" onClick={e => this.handleSubmit(e)} aria-label="maginfying glass icon">
		        <title>magnifying glass icon</title>
		        <use xlinkHref="/assets/build/img/svg-sprite.svg#search"></use>
	        </svg>
            <div className="search-keyword__loader">
              { shouldShowSpinner ? <LoadingSpinner/> : null }
            </div>
          </div>
        </form>
      </label>
    );
  }
}
