// http://redux.js.org/docs/advanced/Middleware.html
import URI from "urijs";

const URL_INIT = "ROUTING/URL_INIT";


/**
 * mapStateToUrl: User provided function to get the URL parameters out of the redux state.
 *                The middleware will use this to update the URL when the redux state changes.
 * @callback mapStateToUrl
 * @param  {Object} state: the current redux state
 * @return {Object} An object with key/value pairs of the parameters to go in the url.
 *                  This object will be passed to URI.search()
 *                  https://medialize.github.io/URI.js/docs.html#accessors-search
 *                  eg { location: state.location.slug } => my-url.com/?location=united-states
 */

/**
 * hangleUrlChange: User provided function to handle updating the state when the url changes
 * @callback hangleUrlChange
 * @param  {Object} urlParams: the current url params
 * @param  {Object} store    : the current redux store.  Use store.getState() and store.dispatch
 * @param  {Object} event    : the popstate event
 * @return {Promise|Any} Return a promise if something in handleUrlChange is disptached async.
 *                       Otherwise, it doesn't matter
 */

/**
 * createRoutingMiddleware: creates a middleware function to be passed to applyMiddleware
 *                          and attaches a window popstate listener
 * @param  {Function} mapStateToUrl   : callback function, see above
 * @param  {Function} handleUrlChange : callback function, see above
 * @param  {Boolean}  handleLoad      : if true, it will call handleUrlChange when the page loads
 * @return {Function} redux middleware
 */
export default function createRoutingMiddleware(mapStateToUrl, handleUrlChange, handleLoad = true) {

    // return the middlware
    // "next" is the next dispatch function
    return store => next => {

        // flags are a code smell, but maybe this is ok in the situation.
        // use replaceState instead of pushState if this dispatch came from the popstate
        let shouldReplace = false;

        // function to run the user-provided handleUrlChange
        const executeUrlChange = (event) => {

            const urlParams = URI(window.location).search(true);

            // set the flag that lets the dispatch function know that it should use replaceState
            shouldReplace = true;

            // call user function to handle the url change.  This function will probably call store.dispatch
            const change = handleUrlChange(urlParams, store, event);

            // if handleUrlChange returns a promise, revert the flag only after it's done
            if (change && typeof(change.then) === "function"){
                change.then(() => { shouldReplace = false; });
            }
            // otherwise, revert it after handleUrlChange is done
            else {
                shouldReplace = false;
            }
        };


        // execute handleUrlChange on load if specified
        if (handleLoad) { executeUrlChange(); }

        // when the url changes...
        window.addEventListener("popstate", (event) => {
            event.preventDefault();
            executeUrlChange(event);
        });


        // our augmented dispatch function
        const dispatch = action => {

            // result is the action itself, this will also update the state
            // http://redux.js.org/docs/api/Store.html#dispatch
            const result = next(action);

            // execute the user function to get the url params out of the newly updated redux state
            const params = mapStateToUrl(store.getState());

            // .search uses a ? in the url
            const url = URI.decode(URI(window.location.href).search(params).toString());

            // if the url is different, pushState/replaceState the new url
            if (!URI(url).equals(window.location.href)) {
                // if we're initializing the url params, replace the url state instead of push
                // eg. /profile?loc=27 > /profile?loc=27&breakdown=...
                // when the browser navigates "back", we don't want to go back to just /profile?loc=27
                // we want all the breakdown=... stuff
                if (action.type === URL_INIT || shouldReplace === true){
                    history.replaceState(null, null, url);
                }
                else {
                    history.pushState(null, null, url);
                }
            }

            return result;
        };

        // dispatch immediately to populate the url params
        // (this only happens when createRoutingMiddleware is called)
        dispatch({ type: URL_INIT });

        // return the new dispatch function
        return dispatch;
    };
}