import R from "ramda";

// number of page to show before and after current page
// if at first or last page, double the number will be shown
// either after or before
const PAGE_BLOCK_RANGE = 3;

export function totalPages(totalResults, resultsPerPage) {
   return Math.ceil(totalResults / resultsPerPage);
}
export function isLastPage(totalResults, resultsPerPage, currentPage) {
  const numPages = totalPages(totalResults, resultsPerPage);
  return currentPage >= numPages;
}

export function isFirstPage(currentPage) {
  return currentPage === 1;
}

// returns a block of page numbers appropriately
// adjacent to the currently selected page
export function pageBlock (totalResults, resultsPerPage, currentPage) {
  const numPages = totalPages(totalResults, resultsPerPage);
  const numPagesVisible = Math.min(numPages, PAGE_BLOCK_RANGE * 2 + 1);
  let start = Math.max(1, currentPage - PAGE_BLOCK_RANGE);

  if (currentPage + PAGE_BLOCK_RANGE > numPages) {
    // (desired page range) - (Size of current page range)
    start -= numPagesVisible - (numPages - (start - 1));
  }

  const pages = R.range(start, start + numPagesVisible);

  return R.map(page => {
    return {num: page, label: page, current: page == currentPage
    };
  }, pages);

}
