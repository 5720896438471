import $ from "jquery";

const SESSION_KEY = "TBF-alert-dismissed";

export default class Alert {

    constructor($el) {
        this.$el = $el;

        this.$el.find(".js-alert-close").click(this.close.bind(this));

        const isDismissed = sessionStorage.getItem(SESSION_KEY) === "true";

        if (!isDismissed){
            this.$el.addClass("is-visible");
        }
	}


    close() {
        sessionStorage.setItem(SESSION_KEY, "true");
        this.$el.removeClass("is-visible");
    }

}
