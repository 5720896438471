import $ from "jquery";
import "jquery-modal";

export class YtVideo {
  constructor(video) {

    this.loadPlayer();

    function onPlayerReady() {
      var parent = $(video);
      var videoId = parent.data('videoId');
      var $trigger = parent.find('.js-video-trigger');
      var modalId = 'js-video-modal__'.concat(videoId);
      parent.find('iframe:not(.initialized)').addClass('initialized').wrap(function () {
        return '<div id='+ modalId +'><div class="iframe-container">'+ $(this).text() +'</div></div>'
      })
      // hide overlay and play video on click
      $trigger.click(function() {
        $('#'+modalId).modal();
      });
    }

    window.onYouTubePlayerAPIReady = function() {
      video.map(function(video) {
        var id = $(video).find(".js-video-wrapper").attr("id");
        var players = new YT.Player(id, {
          events: {
            'onReady': onPlayerReady
          }
        });
        return players;
      });
    }
  }

  /* loads the IFrame Player API code asynchronously */
  loadPlayer(){
    var tag = document.createElement('script');

    tag.src = "//www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}
