import $ from 'jquery';

export class AgendaList {
    constructor($el) {
        this.$el = $el;
        this.$moreBtn = $el.find('.js-more');
        this.$hiddenRows = $el.find('.is-hidden');
        this.bindEvents();
    }

    bindEvents() {
        this.$moreBtn.on("click", (e) => this.expand(e));
    }

    expand() {
        this.$hiddenRows.removeClass('is-hidden');
	    this.$moreBtn.remove();
    }
}