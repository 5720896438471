export const dataSimple = [
  {"Group": "Prisons & Probation", "Value": 145e6, "Sublabel": "(+12%)"},
  {"Group": "Mental Health", "Value": -67e6, "Sublabel": "(-8%)"},
  {"Group": "Public Health", "Value": -139e6, "Sublabel": "(-19%)"},
];

export const data2x5 = [
  {"Subgroup":"18 to 24", "Group":"Female", "Value": 8},
  {"Subgroup":"18 to 24", "Group":"Male", "Value": 21},
  {"Subgroup":"25 to 34", "Group":"Female", "Value": 18},
  {"Subgroup":"25 to 34", "Group":"Male", "Value": 57},
  {"Subgroup":"35 to 49", "Group":"Female", "Value": 18},
  {"Subgroup":"35 to 49", "Group":"Male", "Value": 42},
  {"Subgroup":"50 to 64", "Group":"Female", "Value": 13},
  {"Subgroup":"50 to 64", "Group":"Male", "Value": 24},
  {"Subgroup":"65+", "Group":"Female", "Value": 2},
  {"Subgroup":"65+", "Group":"Male", "Value": 5}
];

export const data5x2 = [
  {"Group":"18 to 24", "Subgroup":"Female", "Value": 8},
  {"Group":"18 to 24", "Subgroup":"Male", "Value": 21},
  {"Group":"25 to 34", "Subgroup":"Female", "Value": 18},
  {"Group":"25 to 34", "Subgroup":"Male", "Value": 57},
  {"Group":"35 to 49", "Subgroup":"Female", "Value": 18},
  {"Group":"35 to 49", "Subgroup":"Male", "Value": 42},
  {"Group":"50 to 64", "Subgroup":"Female", "Value": 13},
  {"Group":"50 to 64", "Subgroup":"Male", "Value": 24},
  {"Group":"65+", "Subgroup":"Female", "Value": 2},
  {"Group":"65+", "Subgroup":"Male", "Value": 5}
];

export const dataGrouped = [
  {"Group":"2011", "Subgroup":"Male", "Value": 0.54, "Sublabel": "Foo"},
  {"Group":"2011", "Subgroup":"Female", "Value": 0.16},
  {"Group":"2012", "Subgroup":"Male", "Value": 0.58},
  {"Group":"2012", "Subgroup":"Female", "Value": 0.27},
  {"Group":"2013", "Subgroup":"Male", "Value": 0.60},
  {"Group":"2013", "Subgroup":"Female", "Value": 0.23},
  {"Group":"2014", "Subgroup":"Male", "Value": 0.57},
  {"Group":"2014", "Subgroup":"Female", "Value": 0.18},
];

export const dataGroupedGrid = [
  {"Group":"18 to 24", "Subgroup":"Female", "Value": 7.1},
  {"Group":"18 to 24", "Subgroup":"Male", "Value": 21.2},
  {"Group":"25 to 34", "Subgroup":"Female", "Value": 17.9},
  {"Group":"25 to 34", "Subgroup":"Male", "Value": 57.2},
  {"Group":"35 to 49", "Subgroup":"Female", "Value": 18.0},
  {"Group":"35 to 49", "Subgroup":"Male", "Value": 42.2},
  {"Group":"50 to 64", "Subgroup":"Female", "Value": 12.9},
  {"Group":"50 to 64", "Subgroup":"Male", "Value": 24.4},
  {"Group":"65+", "Subgroup":"Female", "Value": 2.2},
  {"Group":"65+", "Subgroup":"Male", "Value": 2.5},
];

export const dataStacked = [
  {"Group":"1980", "Subgroup":"Department of correction (state)", "Value": 377},
  {"Group":"1980", "Subgroup":"Houses of correction (State)", "Value": 106},
  {"Group":"1990", "Subgroup":"Department of correction (state)", "Value": 1907},
  {"Group":"1990", "Subgroup":"Houses of correction (State)", "Value": 1502},
  {"Group":"2000", "Subgroup":"Department of correction (state)", "Value": 2855},
  {"Group":"2000", "Subgroup":"Houses of correction (State)", "Value": 2328},
  {"Group":"2010", "Subgroup":"Department of correction (state)", "Value": 3086, "Sublabel": "Foo"},
  {"Group":"2010", "Subgroup":"Houses of correction (State)", "Value": 2571}
];

export const dataSimpleTrend = [
  {"Timeframe":"2005", "Series":"Boston", "Value": 0.51},
  {"Timeframe":"2005", "Series":"Massachusetts", "Value": 0.43},
  {"Timeframe":"2006", "Series":"Boston", "Value": 0.475, "Label": "Below"},
  {"Timeframe":"2006", "Series":"Massachusetts", "Value": 0.425},
  {"Timeframe":"2007", "Series":"Boston", "Value": 0.495},
  {"Timeframe":"2007", "Series":"Massachusetts", "Value": 0.425},
  {"Timeframe":"2008", "Series":"Boston", "Value": 0.52},
  {"Timeframe":"2008", "Series":"Massachusetts", "Value": 0.43},
  {"Timeframe":"2009", "Series":"Boston", "Value": 0.535},
  {"Timeframe":"2009", "Series":"Massachusetts", "Value": 0.45},
  {"Timeframe":"2010", "Series":"Boston", "Value": 0.535},
  {"Timeframe":"2010", "Series":"Massachusetts", "Value": 0.475, "Label": "Above"},
  {"Timeframe":"2011", "Series":"Boston", "Value": 0.54},
  {"Timeframe":"2011", "Series":"Massachusetts", "Value": 0.48},
  {"Timeframe":"2012", "Series":"Boston", "Value": 0.57},
  {"Timeframe":"2012", "Series":"Massachusetts", "Value": 0.53},
  {"Timeframe":"2013", "Series":"Boston", "Value": 0.595},
  {"Timeframe":"2013", "Series":"Massachusetts", "Value": 0.55},
  {"Timeframe":"2014", "Series":"Boston", "Value": 0.595},
  {"Timeframe":"2014", "Series":"Massachusetts", "Value": 0.59},
];
