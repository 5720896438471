import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";

import ReportNav from "./components/ReportNav.jsx";

export function initReportNav(node) {
    const list = $(".js-report-nav-item").map(function(index, item) {
    	return {
    		id:index,
    		name: $(item).data("reportName"),
    		domNode: item
    	}
    });

	ReactDOM.render(
		<ReportNav list={list.toArray()}/>,
		node
	);
}