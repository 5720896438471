import "slick-carousel";

export class PageSlider {
  constructor(slider) {
    this.setupSlider(slider)
  }
  setupSlider(slider) {
    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    })
  }
}


