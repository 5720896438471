import React from "react";
import { func, shape, string, bool } from "prop-types";
import LoadingSpinner from "./LoadingSpinner.jsx";

export default class LoadMore extends React.Component {

  static propTypes = {
    handleClick: func.isRequired,
    loading: bool,
    dictionary: shape({
      loadMoreLabel: string
    })
  }

  state = {
    initiatedLoad: false
  }

  onClick(event) {
    event.preventDefault();
    this.props.handleClick();
    this.setState({
      initiatedLoad: true
    });
  }

  render() {
    const { dictionary } = this.props;
    const shouldShowSpinner = this.props.loading && this.state.initiatedLoad;
    return (
      <div className="search-results__load-more">
        <button className="btn btn--secondary" type="button" onClick={e => this.onClick(e)}>
          { dictionary.loadMoreLabel }
          { shouldShowSpinner ? <LoadingSpinner/> : null }
          </button>
      </div>
    );
  }

}
