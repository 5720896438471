import $ from "jquery";
import { breakpoint } from "../breakpoint";


export class UtilityNav {
    constructor($el) {
      this.$el =  $el;
      this.$navItems = $el.find(".js-nav-item");
      this.openClass = "is-open";

      this.bindEvents();
    }

    bindEvents() {
      // Show sub menus on mouseover
      this.$navItems.filter(".has-submenu").on("mouseenter", event => this.onMouseHover(event));
      this.$navItems.filter(".has-submenu").on("mouseleave", event => this.onMouseHover(event));
    }

    onMouseHover(event) {
      if (breakpoint() === "large"){
        let $hoverItem = $(event.target).closest(".js-nav-item");
        this.toggleMenu($hoverItem);
      }
    }

    toggleMenu($el){
      if ($el.hasClass(this.openClass)){
        this.closeMenu($el);
      }
      else {
        this.openMenu($el);
      }
    }

    openMenu($el){
      $el.addClass(this.openClass);
    }

    closeMenu($el){
      $el.removeClass(this.openClass);
    }

}
